import * as React from "react";
import { Helmet } from "react-helmet";

const PageApp = () => {
  return (
    <div>
      <Helmet>
        <title>源籍站</title>
      </Helmet>
      <p>
        <div
          style={{
            width: "100 %",
            height: "90vh",
            textAlign: "center",
            lineHeight: "90vh",
          }}
        >
          站点维护中
        </div>
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: "40%" }}
        >
          沪ICP备 2022016609号-1
        </a>
      </p>
    </div>
  );
};

export default PageApp;
