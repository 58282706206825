import * as React from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import "./index.css";

const PageMain = () => {
  const [isLogin, setIsLogin] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [userdata, setUserData] = React.useState({});

  React.useEffect(() => {
    // 检查 Cookie 中的 usertoken
    var ctoken = Cookies.get("usertoken");
    if (ctoken == null) {
      ctoken = localStorage.getItem("usertoken");
    }
    if (!ctoken) {
      // 未登录，跳转到登录页面
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6259cd7c2a4e427b&redirect_uri=https%3A%2F%2Fhandapp.com%2Fheros%2Flogin%2Fwechat&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
      return;
    }
    // 登录态
    setToken(ctoken);
    // 换成 localStorage 存储，并清空原有的 Cookie
    localStorage.setItem("usertoken", ctoken);
    Cookies.remove("usertoken");
    // 拉取个人信息
    fetch("https://handapp.com/heros/userinfo", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${ctoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.data);
        setIsLogin(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLogin(false);
      });
  }, []);

  const startOrder = (productID) => {
    // 这里带上登录态去请求后台
    fetch("https://handapp.com/heros/order", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ product_id: productID }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code !== 0) {
          alert(data.message);
          return;
        } else {
          onPayment(data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onPayment = (paydata) => {
    if (window.WeixinJSBridge === undefined) {
      alert("请在微信浏览器中打开");
      return;
    }
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      paydata,
      function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          alert(`支付成功`);
        }
      }
    );
  };

  return (
    <div>
      <Helmet>
        <title>个人中心</title>
      </Helmet>
      {isLogin && (
        <div className="WholePage">
          <div className="ProfileRow">
            <div className="ProfileAvatar">
              <img src={userdata.photo} alt="avatar" className="UserAvatar" />
            </div>
            <div className="ProfileInfo">
              <div className="ProfileName">{userdata.nickname}</div>
              <div className="ProfileID">ID: {userdata.id}</div>
            </div>
          </div>

          <div className="TextLink" onClick={() => startOrder("0")}>
            下单 9.9 元/月
          </div>
          <div className="TextLink" onClick={() => startOrder("1")}>
            下单 29 元/季
          </div>
          <div className="TextLink" onClick={() => startOrder("2")}>
            下单 99 元/年
          </div>
        </div>
      )}
      {!isLogin && <div>正在登录，请稍候...</div>}
    </div>
  );
};

export default PageMain;
